@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    // background: #fff url('./ajax-loader.gif') center center no-repeat;
}

/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;
    margin-top: -30px;
    display: none;

    padding: 0;
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: #fff;

    width: 25px;
    height: 60px;
    text-indent: -99999px;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled
{
    opacity: 0;
}


/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 128px;
}

.slick-dots
{
    display: block;
    width: 100%;
    height: 8px;
    line-height: 0px;
    margin-top: 0px;
    list-style: none;
    text-align: center;
    position: absolute;
    top: -26px;
}
.slick-dots li
{
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;
    width: 8px;
    height: 8px;

    padding: 0px 0px;
    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: none;
    opacity: 1.0;
}

.slick-dots li button:hover:before
{
    opacity: 1.0;
}

.slick-dots li button:before
{

    content: ".";
    display: inline-block;
    margin-right: 10px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #B90000;
    position: relative;
    top:0px;
    left:0px;
}

.slick-dots li.slick-active button{
    opacity: 1.0;
    border-radius: 100%;
    border: none;
    background: #fff;
}

.slick-dots li.slick-active button:before{
    opacity: 0.0
}

.slick-dots li button:hover,
.slick-dots li button:focus
{
    opacity: 1;
}

