@charset "utf-8";

//color
$black: #000;
$white: #fdfdfd;
$white2: #f8f9fb;
$red: #B90000;
$pink: #ff7c7c;
$cyan: #00b9ef;
$gray: #666666;
$gold: #F0D282;

//font-weight
$fw-regular: 500;
$fw-bold: 700;

//breakpoint
$breakpoint1: 1070px;

//breakpoint
$breakpoint2: 768px;

//easing
$easeInSine:      cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine:     cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine:   cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInQuad:      cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad:     cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad:   cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInCubic:     cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic:    cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic:  cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInQuart:     cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart:    cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart:  cubic-bezier(0.77, 0, 0.175, 1);
$easeInQuint:     cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint:    cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint:  cubic-bezier(0.86, 0, 0.07, 1);
$easeInExpo:      cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo:     cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo:   cubic-bezier(1, 0, 0, 1);
$easeInCirc:      cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc:     cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc:   cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInBack:      cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack:     cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack:   cubic-bezier(0.68, -0.55, 0.265, 1.55);

@mixin mediaquery($device) {
  @if $device == pc {
    @media only screen and (min-width: $breakpoint+1) {
      @content; } }
  @else if $device == sp {
    @media only screen and (max-width: $breakpoint) {
      @content; } } }

.clearfix {
  zoom: 1;
  &:after {
    clear: both;
    display: block;
    content: ""; } }

@mixin setFont($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0) {
  font-size: $font-size;
  line-height: $line-height;
  margin-bottom: floor($margin-bottom - ($font-size * $line-height - $font-size) / 2);
  margin-top: floor($margin-top - ($font-size * $line-height - $font-size) / 2); }

@mixin setMargin($font-size: $font-size, $line-height: $line-height, $margin-bottom: 0, $margin-top: 0) {
  margin-bottom: floor($margin-bottom - ($font-size * $line-height - $font-size) / 2);
  margin-top: floor($margin-top - ($font-size * $line-height - $font-size) / 2); }

@mixin setImg($width: auto, $height: auto) {
  width: $width;
  height: $height;

  img {
    width: 100%; } }

@keyframes img-move {
  from {
    background-position: left top; }
  to {
    background-position: left bottom ; } }

@keyframes vertical {
  0% {
    transform: translateY(-10px); }
  100% {
    transform: translateY(0); } }

@keyframes blink {
  0% {
    opacity: 0.5; }
  30% {
    opacity: 1.0; } }

@keyframes spin1 {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes bloom {
  0% {
    transform: scale(1.0); }
  100% {
    transform: scale(0.9); } }

/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Spectral Light'), local('Spectral-Light'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9uSsG3FafaPWnIIMrY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Spectral Light'), local('Spectral-Light'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9uSsG3OafaPWnIIMrY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Spectral Light'), local('Spectral-Light'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9uSsG3PafaPWnIIMrY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Spectral Light'), local('Spectral-Light'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9uSsG3BafaPWnII.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Spectral Regular'), local('Spectral-Regular'), url(https://fonts.gstatic.com/s/spectral/v5/rnCr-xNNww_2s0amA9M9knjsS_ulYHs.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Spectral Regular'), local('Spectral-Regular'), url(https://fonts.gstatic.com/s/spectral/v5/rnCr-xNNww_2s0amA9M2knjsS_ulYHs.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Spectral Regular'), local('Spectral-Regular'), url(https://fonts.gstatic.com/s/spectral/v5/rnCr-xNNww_2s0amA9M3knjsS_ulYHs.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Spectral Regular'), local('Spectral-Regular'), url(https://fonts.gstatic.com/s/spectral/v5/rnCr-xNNww_2s0amA9M5knjsS_ul.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Spectral SemiBold'), local('Spectral-SemiBold'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9vmtm3FafaPWnIIMrY.woff2) format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* vietnamese */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Spectral SemiBold'), local('Spectral-SemiBold'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9vmtm3OafaPWnIIMrY.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Spectral SemiBold'), local('Spectral-SemiBold'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9vmtm3PafaPWnIIMrY.woff2) format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Spectral';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Spectral SemiBold'), local('Spectral-SemiBold'), url(https://fonts.gstatic.com/s/spectral/v5/rnCs-xNNww_2s0amA9vmtm3BafaPWnII.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }
