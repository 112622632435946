@charset "utf-8";
@import "lib/reset.min.css";
@import "_vars.sass";

@media only screen and (min-width: $breakpoint1) {

  .is-sp {
    display: none !important; }

  html {
 }    // overflow-y: scroll;

  body {
    color: $white;
    background: $red;
    background-size: 136px 136px;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: 'Spectral', 'Noto Serif JP', serif;
    font-variant-ligatures: common-ligatures;
    line-height: 1.75;
    overflow-x: hidden;
    font-size: 14px;
    font-weight: 500;
    &.showMenu {
      .btn_menu {
        display: none;
        pointer-events: none; }
      .btn_close {
        display: block;
        pointer-events: auto; } } }

  a {
    color: inherit;
    transition: opacity 0.2s;
    text-decoration: none;
    &:hover {
      opacity: 0.5;
      text-decoration: none; } }
  li {
    list-style: none; }

  #modal-photo {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: black;
    transition: opacity 0.2s ease-in;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.hide {
      opacity: 0.0;
      pointer-events: none; }
    .movie-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 1200px;
      max-height: 1200px;
      width: 100%;
      height: 100%;
      transform: translate(-50%,-50%);
      img {
        height: 100%;
        max-width: 1200px;
        max-height: 1200px; } }

    .btn_photo_close {
      display: block;
      text-align: center;
      position: absolute;
      top: 36px;
      left: 36px;
      width: 45px;
      height: 45px;
      background: $red;
      border-radius: 45px;
      display: block;
      transition: opacity 1.0s $easeOutQuint;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); } } }

  #modal-movie {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: black;
    transition: opacity 0.2s ease-in;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.hide {
      opacity: 0.0;
      pointer-events: none; }
    .movie-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1124px;
      height: 632px;
      transform: translate(-50%,-50%);
      #player {
        iframe {
          width: 100%;
          height: 100%; } } }

    .btn_video_close {
      display: block;
      text-align: center;
      position: absolute;
      top: 36px;
      left: 36px;
      width: 45px;
      height: 45px;
      background: $red;
      border-radius: 45px;
      display: block;
      transition: opacity 1.0s $easeOutQuint;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); } } }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background: $red;
    transition: opacity 0.8s;
    &.hide {
      pointer-events: none;
      opacity: 0.0; }
    .intro {
      width: 142px;
      height: 320px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img {
        position: absolute;
        opacity: 0;
        transition: 0.4s $easeOutSine;
        &.show {
          opacity: 1.0;
          &.i01 {
            transform: translate(0px, 0px); }
          &.i02 {
            transform: translate(0px, 0px); } }
        &.i01 {
          transform: translate(0px, 20px); }
        &.i02 {
          transform: translate(0px, -20px); } } } }

  .slick-next {
    width: 60px;
    height: 60px;
    background: #fff url('/assets/img/common/btn_next.svg') center no-repeat;
    background-size: 25px 13px;
    margin-left: 0px;
    z-index: 1000;
    right: 122px;
    border-radius: 60px; }

  .slick-prev {
    width: 60px;
    height: 60px;
    background: #fff url(/assets/img/common/btn_prev.svg) center no-repeat;
    background-size: 25px 13px;
    margin-left: 0px;
    z-index: 1000;
    left: 122px;
    border-radius: 60px; }

  .btn_menu, .btn_close {
    position: fixed;
    bottom: 36px;
    left: 36px;
    transition: opacity 1.0s $easeOutQuint;
    width: 45px;
    height: 45px;
    background: $black;
    z-index: 101;
    border-radius: 45px;
    display: block;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%); }
    &:hover {
      opacity: 0.6; } }

  .btn_menu {
    display: block;
    pointer-events: auto; }
  .btn_close {
    display: none;
    pointer-events: none; }

  .sns {
    position: fixed;
    z-index: 100;
    position: fixed;
    bottom: 36px;
    right: 36px;
    width: 50%;
    text-align: right;
    transition: background 1.0s $easeOutQuint;
    line-height: 45px;
    .share {
      display: inline-block;
      vertical-align: top;
      a {
        display: inline-block;
        vertical-align: 3px;
        margin-left: 10px;
        transition: opacity 1.0s $easeOutQuint;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        background: $gold;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%); }
        &:hover {
          opacity: 0.6; } } }
    .official {
      display: inline-block;
      font-size: 18px;
      a {
        text-decoration: none;
        transition: opacity 1.0s $easeOutQuint;
        &:hover {
          opacity: 0.6; } } } }

  .pickup {
    background: #900000;
    padding-top: 98px;
    padding-bottom: 98px;
    position: relative;
    > .inner {
 }      // position: relative
    a {
      display: inline-block;
      padding: 0px 47px; } }

  .menu {
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    // text-align: right
    background: $red;
    pointer-events: none;
    opacity: 0.0;
    transition: 0.4s $easeOutQuart;
    .pickup {
      padding-bottom: 178px; }
    &.show {
      pointer-events: auto;
      opacity: 1.0;
      transform: translate(0px,0px); }
    > .inner {
      width: 1356px;
      height: 100vh;
      // height: calc(100vh - 510px)
      margin: 0 auto;
      padding-top: 15px;
      position: relative;
      font-size: 0px;
      letter-spacing: -1000em;
      nav {
        position: absolute;
        top: 50%;
        width: 100vw;
        max-width: 1356px;
        padding-left: 42px;
        padding-right: 42px;
        box-sizing: border-box;
        transform: translate(0px,-50%);
        ul {
          margin-bottom: 30px;
          text-align: center;
          &:first-child {
            li {
              a {
                &:before {
                  display: none; } } } }
          li {
            width: 25%;
            height: 80px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            text-align: center;
            line-height: 1.0;
            &:last-child {
              &:after {
                border-left: black 1px dotted;
                content: "";
                display: block;
                right: 0%;
                top: 0px;
                width: 1px;
                height: 80px;
                position: absolute; } }
            &:before {
              border-left: black 1px dotted;
              content: "";
              display: block;
              left: 0%;
              top: 0px;
              width: 1px;
              height: 80px;
              position: absolute; }
            a {
              display: block;
              text-decoration: none;
              letter-spacing: 0.18em;
              font-size: 20px;
              transition: opacity 1.0s $easeOutQuint;
              color: $gold;
              padding-top: 28px;
              padding-bottom: 28px;
              &.current {
                color: black; }
              &:hover {
                opacity: 0.6; }
              &:before {
                background: black;
                content: "";
                display: block;
                width: 80px;
                height: 3px;
                position: absolute;
                left: 50%;
                top: -18px;
                transform: translate(-50%,0px); }
              span {
                display: inline-block;
                position: relative; }
              i {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                background: white;
                position: absolute;
                top: 50%;
                left: -20px;
                transform: translate(-0px,-50%); } } } } } } }

  footer {
    margin-top: 120px;
    position: relative;
    padding-top: 40px;
    padding-bottom: 180px;
    text-align: center;
    background: black;
    ul {
      margin-bottom: 30px;
      li {
        display: inline-block;
        margin-right: 36px;
        font-size: 12px;
        a {
          text-decoration: none; } } }
    p.note {
      margin-bottom: 120px;
      font-size: 12px; }
    p.copyright {
      font-size: 18px;
      letter-spacing: 0.18em;
      color: $gold; } }

  .container {
    position: absolute;
    top: 0px;
    width: 100%;
    // left: 50%

    section.logo {
      text-align: center;
      padding-top: 90px;
      padding-bottom: 120px; }

    .n-title {
      font-size: 30px;
      text-align: center;
      margin-bottom: 180px;
      line-height: 1.0;
      letter-spacing: 0.18em;
      font-weight: 500;
      &.mbs {
        margin-bottom: 60px; }
      &.mt {
        margin-top: 180px; } }

    section.kv {
      margin-left: 42px;
      margin-right: 42px;
      box-sizing: border-box;
      overflow: hidden;
      h1 {
        max-width: 1440px;
        margin: 0 auto;
        position: relative; }
      .title {
        position: absolute;
        top: 0px;
        left: 0px; } }

    section.copy {
      text-align: center;
      > .inner {
        margin: 0 auto;
        padding: 180px 0px;
        .cp {
          img {
            width: 892px; } }
        .onm {
          display: inline-block;
          margin: 60px 0px; }
        .onair {
          text-align: center;
          p {
            letter-spacing: 0.18em;
            position: relative;
            &:before {
              position: absolute;
              content: "";
              display: block;
              top: -99px;
              left: 50%;
              margin-left: -30px;
              width: 60px;
              height: 3px;
              background: $black; }
            margin-top: 190px;
            font-size: 24px;
            span {
              color: $gold; }
            em {
              font-style: normal;
              font-size: 18px;
              color: $gold; } }
          img {
            display: inline-block;
            // margin-bottom: 30px
            width: 828px; } } } }

    section.letter {
      background: $black;
      > .inner {
        padding: 180px 42px;
        margin: 0 auto;
        max-width: 1440px;
        box-sizing: border-box;

        h2 {
          font-size: 24px;
          letter-spacing: 0.2em;
          text-align: center;
          position: relative;
          margin-bottom: 42px;
          line-height: 1.0;
          font-weight: 500; }
        p.desc {
          font-size: 16px;
          text-align: center;
          margin-bottom: 60px; }
        .pp {
          font-size: 12px;
          margin-top: 20px; }

        .mod {
          margin-top: 36px; }
        .ltwrapper {
          font-size: 0px;
          form {
            p {
              margin-bottom: 20px; } }
          &:first-child {
            .ltl {
              margin-right: 30px; } }
          .ltl {
            width: calc(100% * 660/1356);
            float: left; }
          .ltr {
            width: calc(100% * 660/1356);
            float: right; }
          input[type="text"] {
            width: calc(100% - 232px); } }
        .ltwrapper2 {
          font-size: 0px;
          &:first-child {
            .ltl {
              margin-right: 30px; } }
          .ltl {
            width: calc(100% * 544/1356);
            float: left;
            input[type="text"] {
              width: calc(100%); } }
          .ltr {
            width: calc(100% * 776/1356);
            float: right;
            input[type="text"] {
              width: calc(100% - 232px); } } }
        form {
          p {
            font-size: 12px;
            line-height: 1.0; }
          span {
            font-size: 12px;
            display: block;
            margin-bottom: 20px;
 }            // line-height: 1.0
          input[type="text"] {
            // margin-top: 15px
            // display: inline-block
            float: left;
            font-size: 18px;
            padding: 13px;
            box-sizing: border-box;
            border: none;
            border-radius: 0px;

 }            // margin-bottom: 30px
          input[type="submit"] {
            line-height: 48px;
            float: left;
            cursor: pointer;
            border-radius: 0;
            -webkit-box-sizing: content-box;
            -webkit-appearance: button;
            appearance: button;
            border: none;
            box-sizing: border-box;
            font-family: 'Noto Serif JP', serif;
            display: inline-block;
            width: 232px;
            font-size: 18px;
            box-sizing: border-box;
            border: none;
            border-radius: 0px;
            background: $red;
            color: white;
            font-weight: bold; } } } }

    section.intro {
      margin-left: 42px;
      margin-right: 42px;
      > .inner {
        max-width: 1440px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 180px;
        figure {
          margin-bottom: 180px; }
        p {
          width: 892px;
          margin: 0 auto;
          font-size: 30px;
          line-height: 2.0;
          text-align: left;
          span {
            display: inline-block;
            position: relative;
            em {
              text-align: center;
              font-style: normal;
              font-size: 14px;
              position: absolute;
              display: block;
              width: 100%;
              left: 50%;
              top: -10px;
              transform: translate(-50%,0px); } } } } }


    section.novels,
    section.comics {
      padding-top: 180px;
      padding-bottom: 180px;
      h2 {
        text-align: center;
        font-size: 30px;
        letter-spacing: 0.18em;
        margin-bottom: 90px;
        font-weight: 500; }
      ul {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1356px;
        margin: 0 auto;
        text-align: center;
        font-size: 0px;
        li {
          display: inline-block;
          font-size: 0;
          text-align: center;
          font-size: 0;
          width: 196px;
          margin-bottom: 60px;
          margin-left: 10px;
          margin-right: 10px;
          img {
            border: 1px solid $red;
            width: 100%; } } } }
    section.white {
      background: white;
      h2 {
        color: black; } }
    section.black {
      background: black;
      h2 {
        color: white; } }

    section.staff,
    section.cast {
      background: black;
      padding-left: 42px;
      padding-right: 42px;
      padding-top: 180px;
      padding-bottom: 180px;
      > .inner {
        max-width: 1440px;
        margin: 0 auto;
        position: relative; }
      ul {
        li {
          font-size: 30px;
          width: 25%;
          float: left;
          text-align: center;
          margin-bottom: 130px;
          position: relative;
          &:last-child {
            margin-bottom: 0px; }
          &:before {
            position: absolute;
            content: "";
            display: block;
            top: -65px;
            left: 50%;
            margin-left: -40px;
            width: 80px;
            height: 3px;
            background: $red; }
          span {
            display: block;
            font-size: 18px;
            color: $gold;
            font-weight: 500;
            margin-bottom: 12px; }
          em {
            margin-top: 12px;
            font-style: normal;
            font-size: 12px;
            display: block; } } } }

    section.staff {
      ul {
        li {
          &:first-child {
            &:before {
              display: none; } }
          &.l {
            width: 100%;
            margin-right: 0px; } } } }

    section.cast {
      ul {
        li {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            &:before {
              display: none; } }
          &:last-child {
            margin-bottom: 0px; } } } }

    section.characters {
      padding-bottom: 180px;
      .chr {
        &#ch01 {
          padding: 136px  0px;
          figure {
            right: 106px; } }
        &#ch02 {
          padding: 89px  0px;
          figure {
            left: 155px; } }
        &#ch03 {
          padding: 90px  0px;
          figure {
            right: 75px; } }
        &#ch04 {
          padding: 36px 0px;
          figure {
            left: 206px; } }
        &#ch05 {
          padding: 84px 0px;
          figure {
            right: 164px; } }
        &#ch06 {
          padding: 74px 0px;
          figure {
            left: 192px; } }
        &#ch07 {
          padding: 148px 0px;
          figure {
            right: 188px; } }
        &#ch08 {
          padding: 136px 0px;
          figure {
            left: 138px; } }
        &:nth-child(even) {
          >.inner {
            .txt {
              >.inner {
                margin-left: 580px; } } } }
        >.inner {
          background: black;
          color: white;
          figure {
            position: absolute;
            top: 50%;
            transform: translate(0px,-50%); }
          .txt {
            width: 1124px;
            margin: 0 auto;
            position: relative;
            > .inner {
              width: 544px;
              padding-top: 90px;
              padding-bottom: 90px; }
            .name {
              font-size: 30px; }
            .cv {
              font-size: 18px;
              color: $gold;
              margin-top: 45px; }
            .desc {
              font-size: 18px;
              margin-top: 42px; } } } } }

    section.categories {
      margin-bottom: 180px;
      nav {
        margin: 0 auto;
        text-align: center;
        ul {
          font-size: 0px;
          letter-spacing: -10000em;
          li {
            font-size: 18px;
            letter-spacing: 0em;
            display: inline-block;
            line-height: 60px;
            border-left: black 1px dotted;
            &.current {
              a {
                color: black; } }
            &:last-child {
              border-right: black 1px dotted; }
            a {
              color: $gold;
              text-decoration: none;
              padding-left: 42px;
              padding-right: 42px;
              display: block;
              line-height: 60px; } } } } }

    section.more {
      text-align: center;
      a {
        text-decoration: none;
        display: block;
        background: black;
        color: white;
        font-size: 30px;
        padding-top: 60px;
        padding-bottom: 60px;
        letter-spacing: 0.18em;
        &:hover {
          color: $gold; } } }

    section.news-items {
      margin-left: 42px;
      margin-right: 42px;
      > .inner {
        max-width: 1440px;
        margin: 0 auto;
        .item {
          margin-bottom: 120px;
          &:last-child {
            margin-bottom: 180px; }
          > .inner {
            width: calc(100% - 460px);
            margin: 0 auto;
            position: relative;
            background: black;
            .info {
              position: absolute;
              top: 0px;
              left: -232px;
              line-height: 1.0;
              .date {
                font-size: 30px;
                color: white;
                line-height: 48px;
                letter-spacing: 0.18em; }
              .category {
                font-size: 18px;
                color: black;
                line-height: 21px; } }
            .entry {
              padding: 60px 116px;
              box-sizing: border-box;
              img {
                width: calc(100% + 116px + 116px);
                margin-left: -116px; }
              .title {
                font-size: 24px;
                margin-bottom: 60px; }
              .body {
                a {
                  color: $red; } } } } } } }

    section.subtitles {
      margin-bottom: 180px;
      &.sub-s {
        nav {
          ul {
            li {
              width: 33.3%;
              height: auto;
              .num {
                letter-spacing: 0.18em;
                text-transform: uppercase; } } } } }
      nav {
        max-width: 1440px;
        margin: 0 auto;
        text-align: center;
        span.ruby {
          display: inline-block;
          position: relative;
          em {
            text-align: center;
            font-style: normal;
            font-size: 10px;
            position: absolute;
            display: block;
            width: 200%;
            left: 50%;
            top: -9px;
            transform: translate(-50%,0px); } }
        ul {
          font-size: 0px;
          letter-spacing: -10000em;
          li {
            font-size: 18px;
            width: 33.3%;
            height: 130px;
            letter-spacing: 0em;
            display: inline-block;
            vertical-align: top;
            border-left: black 1px dotted;
            box-sizing: border-box;
            margin-bottom: 30px;
            position: relative;
            .inner {
              width: 100%;
              position: absolute;
              top: 50%;
              transform: translate(0px,-50%); }
            &.current {
              a {
                color: black; } }
            &:nth-child(3n) {
              border-right: black 1px dotted; }
            &:last-child {
              border-right: black 1px dotted; }
            a {
              color: $gold;
              text-decoration: none;
              display: block;
              padding-top: 10px;
              padding-bottom: 10px;
              .ep {
                line-height: 1.0;
                font-size: 12px;
                margin-bottom: 5px; }
              .num {
                line-height: 1.0;
                font-size: 36px;
 }                // margin-bottom: 5px
              .ttl {
                // height: 62px
                width: 100%;
                font-size: 18px;
                position: relative; } } } } } }
    section.story {
      padding-left: 42px;
      padding-right: 42px;
      background: black;
      margin-top: 160px;
      padding-top: 139px;
      padding-bottom: 179px;
      > .inner {
        max-width: 1440px;
        margin: 0 auto;

        span.ruby {
          display: inline-block;
          position: relative;
          em {
            text-align: center;
            font-style: normal;
            font-size: 10px;
            position: absolute;
            display: block;
            width: 200%;
            left: 50%;
            top: -9px;
            transform: translate(-50%,0px); } }

        .story_title {
          padding-bottom: 120px;
          line-height: 1.0;
          text-align: center;
          span {
 }            // display: block
          .num {
            font-size: 48px;
            margin-bottom: 19px; }
          .ttl {
            letter-spacing: 0.18em;
            font-size: 30px;
            margin-bottom: 15px;
            line-height: 1.5; }

          .en {
            color: $gold;
            font-size: 15px; } }
        .movie {
          width: 100%;
          position: relative;
          &:before {
            content: "";
            display: block;
            padding-top: 56.25%; }
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%; } }
        .still-l {
          img {
            width: 100%; } }
        .still {
          margin-top: 120px;
          margin-bottom: 120px;
          &.str {
            text-align: right; }
          img {
            width: 892px; } }
        .story {
          margin-top: 120px;
          margin-bottom: 120px;
          font-size: 18px;
          line-height: 2.0;
          &.str {
            text-align: right; }
          > .inner {
            display: inline-block;
            text-align: left;
            width: 892px; } }
        .stills {
          font-size: 0px;
          letter-spacing: -10000em;
          img {
            width: calc(50% - 18px);
            display: inline-block;
            &:nth-child(odd) {
              margin-right: 36px; } } } } }

    section.tile-items {
      padding-left: 24px;
      padding-right: 24px;
      > .inner {
        max-width: 1392px;
        margin: 0 auto;
        .items {
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            width: calc(33.333%);
            display: inline-block;
            vertical-align: top;
            font-size: 24px;
            letter-spacing: 0em;
            padding-left: 18px;
            padding-right: 18px;
            box-sizing: border-box;
            margin-bottom: 120px;
            figure {
              a {
                display: block;
                img {
                  width: 100%; } } }
            .title {
              font-size: 24px;
              line-height: 2.0;
              margin-bottom: 42px;
              margin-top: 20px; }
            .sub {
              font-size: 18px;
              line-height: 2.0;
              margin-top: 24px;
              .date {
                display: inline-block;
                margin-right: 24px; }
              a {
                color: black; } } } } } }

    section.special {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 180px;
      padding-bottom: 180px;
      > .inner {
        max-width: 1392px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 40px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .desc {
          font-size: 18px;
          margin-bottom: 90px;
          text-align: center;
          letter-spacing: 0.18em; } } }

    section.times {
      background: black;
      color: white;
      .items {
        text-align: center;
        width: 892px;
        margin: 0 auto;
        font-size: 0px;
        letter-spacing: -10000em;
        .item {
          display: inline-block;
          vertical-align: top;
          font-size: 0px;
          letter-spacing: 0.0em;
          &:nth-child(odd) {
            margin-right: 36px;
            margin-bottom: {} }
          &:last-child {
            margin-bottom: 0px; }
          figure {
            width: 196px;
            margin: 0 auto;
            img {
              width: 100%; } }
          .vol {
            font-size: 36px;
            letter-spacing: 0.18em;
            margin-bottom: 36px;
            margin-top: 36px; }
          a {
            display: inline-block;
            width: 428px;
            font-size: 18px;
            background: $red;
            color: white;
            line-height: 48px; } } } }

    section.voice {
      background: white;
      color: black;
      padding-bottom: 120px;
      .items-l {
        text-align: center;
        margin-bottom: 90px;
        > .item {
          display: inline-block;
          width: 100%;
          font-size: 0px;
          letter-spacing: -10000em;
          img {
            display: inline-block;
            vertical-align: 0px;
            margin-right: 60px; }
          .btn_sound {
            display: inline-block;
            vertical-align: 40;
            text-align: center;
            width: 80px;
            height: 80px;
            background: $red;
            border-radius: 80px;
            transition: opacity 1.0s $easeOutQuint;
            position: relative;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%); } }
          .txt {
            font-size: 0px;
            letter-spacing: 0em;
            display: inline-block;
            vertical-align: 50;
            text-align: left;
            color: $red;
            margin-left: 60px;
            .date {
              font-size: 30px;
              letter-spacing: 0.18em; }
            .name {
              font-size: 18px; } } } }
      .items {
        text-align: center;
        font-size: 0px;
        letter-spacing: -10000em;
        > .item {
          display: inline-block;
          width: 80px;
          line-height: 1.0;
          margin-left: 18px;
          margin-right: 18px;
          margin-bottom: 60px;
          .txt {
            letter-spacing: 0em;
            font-size: 15px;
            margin-top: 20px; } } } }

    section.music {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 180px;
      padding-bottom: 180px;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 40px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .title {
          text-align: center;
          font-weight: 500;
          margin-bottom: 90px;
          letter-spacing: 0.18em;
          .artist {
            font-size: 24px;
            margin-bottom: 20px; }
          h3 {
            letter-spacing: 0.06em;
            font-size: 30px;
            margin-bottom: 54px;
            font-weight: 500; }
          .credit {
            font-size: 18px; } }
        .txts {
          position: relative;
          img {
            position: absolute;
            width: 544px;
            top: 0px;
            right: 0px; }
          .txt-block {
            width: calc(100% - 580px);
            margin-bottom: 90px;
            &:last-child {
              margin-bottom: 0px; }
            .head {
              margin-bottom: 36px;
              letter-spacing: 0.18em; }
            ul,ol {
              margin-bottom: 1em; }
            ol {
              li {
                list-style-type: decimal-leading-zero;
                margin-left: 1.6em; } }
            .tracks2 {
              font-size: 0px;
              .tracks {
                font-size: 14px;
                display: inline-block;
                vertical-align: top;
                width: 50%; } } } } } }


    section.music.white {
      background: white;
      color: black;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $red; }
            a {
              color: $red; } } } } }

    section.music.black {
      background: black;
      color: white;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $gold; }
            a {
              color: $gold; } } } } }

    section.onair {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 180px;
      padding-bottom: 180px;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 40px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        ul {
          font-size: 0px;
          li {
            font-size: 18px;
            text-align: center;
            margin-bottom: 60px;
            position: relative;
            width: 50%;
            display: inline-block;
            &:last-child {
              margin-bottom: 0px; }
            // &:first-child
            //   &:before
            //     display: none
            // &:before
            //   position: absolute;
            //   content: ""
            //   display: block
            //   top: -65px;
            //   left: 50%;
            //   margin-left: -40px
            //   width: 80px
            //   height: 3px
            //   background: $red
            span {
              display: block;
              font-size: 30px;
              color: $gold;
              font-weight: 500;
              margin-bottom: 12px; }
            em {
              font-style: normal;
              display: block; } } } } }

    section.onair.black {
      background: black;
      color: white;
      > .inner {
        ul {
          li {
            display: block;
            width: 100%;
            span {
              color: $gold; } } } } }

    section.onair.white {
      background: white;
      color: black;
      > .inner {
        ul {
          li {
            &:first-child {
              &:before {
                display: none; } }
            &:nth-child(2) {
              &:before {
                display: none; } }
            &:before {
              background: $gold; }
            span {
              color: $red; } } } } }

    section.disc {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 180px;
      padding-bottom: 180px;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 120px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .info {
          img {
            float: right;
            width: 544px;
            border: 1px solid $red; }
          .txts {
            float: left;
            width: calc(100% - 580px);
            a.pv {
              display: block;
              text-align: center;
              background: $red;
              width: 100%;
              line-height: 1.0;
              font-size: 18px;
              padding-top: 15px;
              padding-bottom: 15px; }
            a.aniplexplus {
              display: block;
              text-align: center;
              background: white;
              color: black;
              width: 100%;
              line-height: 1.0;
              font-size: 18px;
              padding-top: 15px;
              padding-bottom: 15px;
              margin-top: 17px; }
            .txt-block {
              margin-bottom: 90px;
              font-size: 15px;
              &:last-child {
                margin-bottom: 0px; }
              .head {
                margin-bottom: 36px;
                letter-spacing: 0.18em; }
              p {
                margin-bottom: 36px; }
              ul {
                li {
                  list-style-type: disc;
                  margin-left: 1.6em; } }
              ol {
                li {
                  list-style-type: decimal-leading-zero;
                  margin-left: 1.6em; } } }
            .block-l {
              .head {
                font-size: 18px; }
              p.body-l {
                font-size: 24px;
                span.ruby {
                  display: inline-block;
                  position: relative;
                  em {
                    text-align: center;
                    font-style: normal;
                    font-size: 10px;
                    position: absolute;
                    display: block;
                    width: 200%;
                    left: 50%;
                    top: -9px;
                    transform: translate(-50%,0px); } } } } } } } }

    section.disc.white {
      background: white;
      color: black;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $red; }
            a {
              color: $red; } } } } }

    section.disc.black {
      background: black;
      color: white;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $gold; }
            a {
              color: $gold; } } } } }

    section.bonus {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 180px;
      padding-bottom: 60px;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        .txts {
          .txt-block {
            margin-bottom: 90px;
            position: relative;
            font-size: 15px;
            &:last-child {
              margin-bottom: 0px; }
            .head {
              margin-bottom: 36px;
              letter-spacing: 0.18em;
              position: absolute;
              top: 0px;
              left: 0px;
              color: $gold; }
            .body {
              margin-left: 232px;
              width: 660px;
              margin-bottom: 36px;
              span {
                color: $gold; } } } } } }

    section.radio {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 180px;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 80px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500;
          color: $gold; }
        p {
          width: 660px;
          margin: 0 auto; } } }

    section.radio-program {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 120px;
      padding-bottom: 30px;
      background: black;
      color: white;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 90px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .ps {
          .p {
            width: 660px;
            margin: 0 auto;
            margin-bottom: 90px;
            .num {
              font-size: 36px;
              color: $gold;
              text-align: center;
              line-height: 1.0;
              margin-bottom: 40px; }
            .title {
              font-size: 24px;
              color: $gold;
              text-align: center;
              line-height: 1.0;
              margin-bottom: 40px; }
            a {
              color: $gold; } } } } }

    section.radio-links {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 120px;
      padding-bottom: 120px;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 90px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .links {
          text-align: center;
          a {
            display: inline-block;
            text-align: center;
            background: white;
            color: black;
            width: 428px;
            line-height: 1.0;
            font-size: 18px;
            padding-top: 15px;
            padding-bottom: 15px;
            margin: 0px 18px; } } } }

    section.radio-archives {
      padding-left: 24px;
      padding-right: 24px;
      padding-top: 120px;
      padding-bottom: 120px;
      background: black;
      color: white;
      > .inner {
        max-width: 1124px;
        margin: 0 auto;
        h2 {
          font-size: 30px;
          margin-bottom: 90px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .links {
          text-align: center;
          a {
            display: inline-block;
            text-align: center;
            background: white;
            color: black;
            width: 312px;
            line-height: 1.0;
            font-size: 36px;
            padding-top: 30px;
            padding-bottom: 30px;
            margin: 0px 18px;
            span {
              line-height: 18px;
              display: block;
              font-size: 18px;
              margin-top: 15px; } } } } }

    section.countdown {
      background: black;
      color: white;
      padding-bottom: 90px;
      .items {
        text-align: center;
        width: 1124px;
        margin: 0 auto;
        font-size: 0px;
        letter-spacing: -10000em;
        .item {
          display: inline-block;
          vertical-align: top;
          font-size: 0px;
          letter-spacing: 0.0em;
          width: 374px;
          figure {
            width: 196px;
            margin: 0 auto;
            img {
              width: 100%; } }
          .vol {
            font-size: 36px;
            letter-spacing: 0.18em;
            margin-bottom: 36px;
            margin-top: 36px; }
          .name {
            font-size: 24px;
            margin-bottom: 23px; }
          .desc {
            font-size: 14px; } } } }

    section.location {
      .inner {
        display: none;
        &:first-child {
          display: block; } }
      >.inner {
        max-width: 1256px; }
      nav {
        text-align: center;
        margin-bottom: 60px;
        li {
          display: inline-block;
          margin: 0px 80px;
          a {
            letter-spacing: 0.18em;
            font-size: 36px;
            color: $gold;
            display: block;
            &.current {
              color: black; } } } }
      .content {
        width: 100%;
        background: black;
        .inner {
          padding-top: 80px;
          padding-bottom: 80px;
          width: 1124px;
          margin: 0 auto; }
        .items {
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            width: 544px;
            display: inline-block;
            vertical-align: top;
            letter-spacing: 0em;
            margin-left: 36px;
            margin-bottom: 36px;
            &:nth-child(odd) {
              margin-left: 0px; } }
          .photo {
            span {
              line-height: 1.0;
              font-size: 30px;
              color: $gold;
              margin-bottom: 18px;
              display: inline-block; }
            figure {
              width: 544px;
              img {
                width: 100%; } } }
          .desc {
            text-align: left;
            font-size: 15px;
            padding-top: 48px;
            p {
              margin-bottom: 1em;
              span {
                color: $gold; } } } } } } } }

@media only screen and (max-height: 900px) {
  .menu {
    > .inner {
        height: 100vh; }
    .pickup {
      display: none; } } }

