@charset "utf-8";
@import "lib/reset.min.css";
@import "lib/slick.css";
@import "lib/slick-theme.css";
@import "_vars.sass";

@media only screen and (max-width: $breakpoint1  - 1) {

  .is-pc {
    display: none !important; }

  html {
    overflow-y: scroll; }

  body {
    color: $white;
    background: $red;
    background-size: 136px 136px;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    font-feature-settings: "palt";
    font-family: 'Noto Serif JP', serif;
    letter-spacing: 0.0em;
    line-height: 1.5;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 500;
    &.showMenu {
      .btn_menu {
        display: none;
        pointer-events: none; }
      .btn_close {
        display: block;
        pointer-events: auto; } } }


  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none; } }
  li {
    list-style: none; }

  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100001;
    background: $red;
    transition: opacity 0.8s;
    &.hide {
      pointer-events: none;
      opacity: 0.0; }

    .intro {
      width: 142px;
      height: 320px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0.7, 0.7);

      img {
        position: absolute;
        opacity: 0;
        transition: 0.4s $easeOutSine;
        &.show {
          opacity: 1.0;
          &.i01 {
            transform: translate(0px, 0px); }
          &.i02 {
            transform: translate(0px, 0px); } }
        &.i01 {
          transform: translate(0px, 20px); }
        &.i02 {
          transform: translate(0px, -20px); } } } }

  #modal-photo {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: black;
    transition: opacity 0.2s ease-in;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.hide {
      opacity: 0.0;
      pointer-events: none; }
    .movie-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      // height: 100vh
      transform: translate(-50%,-50%);
      img {
        width: 100%; } }

    .btn_photo_close {
      display: block;
      text-align: center;
      position: absolute;
      top: 15px;
      left: 15px;
      width: 45px;
      height: 45px;
      background: $red;
      border-radius: 45px;
      display: block;
      transition: opacity 1.0s $easeOutQuint;
      z-index: 100;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); } } }

  #modal-movie {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
    background: black;
    transition: opacity 0.2s ease-in;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &.hide {
      opacity: 0.0;
      pointer-events: none; }
    .movie-area {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100vw;
      height: calc(100vw / 16 * 9);
      transform: translate(-50%,-50%);
      #player {
        iframe {
          width: 100%;
          height: 100%; } } }

    .btn_video_close {
      display: block;
      text-align: center;
      position: absolute;
      top: 15px;
      left: 15px;
      width: 45px;
      height: 45px;
      background: $red;
      border-radius: 45px;
      display: block;
      transition: opacity 1.0s $easeOutQuint;
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%); } } }


  .slick-next {
    display: none !important; }

  .slick-prev {
    display: none !important; }

  .btn_menu, .btn_close {
    position: fixed;
    bottom: 30px;
    right: 15px;
    transition: opacity 1.0s $easeOutQuint;
    width: 45px;
    height: 45px;
    background: $black;
    z-index: 101;
    border-radius: 45px;
    display: block;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%); }
    &:hover {
      opacity: 0.6; } }

  .btn_menu {
    display: block;
    pointer-events: auto; }

  .btn_close {
    display: none;
    pointer-events: none; }

  .sns {
    position: fixed;
    z-index: 100;
    position: fixed;
    bottom: 30px;
    left: 15px;
    width: 50%;
    text-align: left;
    transition: background 1.0s $easeOutQuint;
    line-height: 45px;
    .share {
      display: inline-block;
      vertical-align: top;
      a {
        display: inline-block;
        vertical-align: 3px;
        margin-left: 10px;
        transition: opacity 1.0s $easeOutQuint;
        width: 45px;
        height: 45px;
        border-radius: 45px;
        background: $gold;
        position: relative;
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%); }
        &:hover {
          opacity: 0.6; } } }
    .official {
      position: fixed;
      top: 235px;
      right: 15px;
      transform: rotate(90deg);
      transform-origin: right center;
      font-size: 12px;
      line-height: 0px; } }

  .pickup {
    background: #900000;
    padding-top: 90px;
    padding-bottom: 90px;
    position: relative;
    > .inner {
 }      // position: relative
    a {
      display: inline-block;
      padding: 0px 7px;
      img {
        width: 255px;
        height: 192px; } } }

  .menu {
    // display: none
    position: fixed;
    z-index: 100;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: $red;
    pointer-events: none;
    opacity: 0.0;
    transition: opacity 0.4s $easeOutQuart;
    .pickup {
      display: none; }
    &.show {
      pointer-events: auto;
      opacity: 1.0;
      transform: translate(0px,0px); }

    > .inner {
      margin: 0 auto;
      padding-top: 15px;
      position: relative;
      font-size: 0px;
      letter-spacing: -1000em;
      width: 100%;
      height: 100%;
      nav {
        position: absolute;
        width: 100vw;
        top: 50%;
        transform: translate(0px,-50%);
        ul {
          text-align: center;
          &:first-child {
            li {
              &:first-child {
                a {
                  &:before {
                    display: none; } } } } }
          li {
            width: 100%;
            vertical-align: top;
            position: relative;
            text-align: center;
            line-height: 1.0;
            a {
              display: block;
              text-decoration: none;
              letter-spacing: 0.18em;
              font-size: 14px;
              transition: opacity 1.0s $easeOutQuint;
              color: $gold;
              padding-top: 14px;
              padding-bottom: 14px;
              &.current {
                color: black; }
              &:before {
                background: black;
                content: "";
                display: block;
                width: 45px;
                height: 2px;
                position: absolute;
                left: 50%;
                top: -0px;
                transform: translate(-50%,0px); }
              span {
                display: inline-block;
                position: relative; }
              i {
                display: block;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                background: white;
                position: absolute;
                top: 50%;
                left: -20px;
                transform: translate(-0px,-50%); } } } } } } }

  footer {
    position: relative;
    margin-top: 90px;
    padding-top: 90px;
    padding-bottom: 90px;
    text-align: center;
    background: black;
    ul {
      margin-bottom: 32px;
      font-size: 0px;
      letter-spacing: -1000em;
      li {
        letter-spacing: 0em;
        width: 50%;
        display: inline-block;
        font-size: 12px;
        margin-bottom: 32px;
        a {
          text-decoration: none; } } }
    p.note {
      margin-bottom: 64px;
      font-size: 12px;
      line-height: 1.8; }
    p.copyright {
      font-size: 18px;
      letter-spacing: 0.18em;
      color: $gold; } }

  .container {
    position: absolute;
    top: 0px;
    width: 100%;

    section.logo {
      text-align: center;
      padding-top: 60px;
      margin-bottom: 60px; }

    .n-title {
      font-size: 24px;
      text-align: center;
      margin-bottom: 90px;
      line-height: 1.0;
      letter-spacing: 0.18em;
      font-weight: 500;
      margin-top: 90px;
      &.mbs {
        margin-bottom: 60px; }
      &.mt {
        margin-top: 90px; } }

    section.kv {
      box-sizing: border-box;
      overflow: hidden; }

    section.copy {
      text-align: center;
      > .inner {
        margin: 0 auto;
        padding: 90px 0px;
        .cp {
          img {
            width: 320px; } }
        .onm {
          display: inline-block;
          margin: 60px 0px;
          img {
            width: 80px; } }

        .onair {
          text-align: center;
          p {
            letter-spacing: 0.18em;
            position: relative;
            margin-top: 130px;
            font-size: 18px;
            line-height: 2.0;
            &:before {
              position: absolute;
              content: "";
              display: block;
              top: -64px;
              left: 50%;
              margin-left: -22px;
              width: 44px;
              height: 3px;
              background: $black; }
            span {
              color: $gold; }
            em {
              font-style: normal;
              font-size: 12px;
              color: $gold; } }
          img {
            display: inline-block; } } } }

    section.letter {
      background: $black;
      > .inner {
        padding: 60px 15px;
        margin: 0 auto;
        box-sizing: border-box;
        h2 {
          font-size: 24px;
          letter-spacing: 0.2em;
          text-align: center;
          position: relative;
          margin-bottom: 32px;
          line-height: 1.0; }
        p.desc {
          font-size: 12px;
          text-align: center;
          margin-bottom: 64px; }
        .pp {
          font-size: 12px;
          margin-top: 20px; }

        .ltwrapper {
          font-size: 0px;
          form {
            p {
              margin-bottom: 20px; } }
          &:first-child {
            .ltl {
              margin-right: 30px; } }
          .ltl {
            width: calc(100%); }
          .ltr {
            width: calc(100%); }
          input[type="text"] {
            width: calc(100% - 90px); } }
        .ltwrapper2 {
          font-size: 0px;
          margin-top: 18px;
          &:first-child {
            .ltl {
              margin-right: 30px; } }
          .ltl {
            width: calc(100% - 90px);
            float: left;
            input[type="text"] {
              width: calc(100%); } }
          .ltr {
            width: calc(100%);
            float: right;
            input[type="text"] {
              width: calc(100% - 90px); } } }
        form {
          p {
            font-size: 12px;
            line-height: 1.0; }
          span {
            font-size: 12px;
            display: block;
            margin-bottom: 20px;
 }            // line-height: 1.0
          input[type="text"] {
            // margin-top: 15px
            // display: inline-block
            float: left;
            font-size: 18px;
            padding: 13px;
            box-sizing: border-box;
            border: none;
            border-radius: 0px;
            margin-bottom: 18px; }
          input[type="submit"] {
            line-height: 48px;
            float: left;
            cursor: pointer;
            border-radius: 0;
            -webkit-box-sizing: content-box;
            -webkit-appearance: button;
            appearance: button;
            border: none;
            box-sizing: border-box;
            font-family: 'Noto Serif JP', serif;
            display: inline-block;
            width: 90px;
            font-size: 18px;
            box-sizing: border-box;
            border: none;
            border-radius: 0px;
            background: $red;
            color: white;
            font-weight: bold;
            margin-bottom: 18px; } } } }

    section.intro {
      margin-left: 15px;
      margin-right: 15px;
      > .inner {
        margin: 0 auto;
        padding-bottom: 90px;
        figure {
          margin-bottom: 90px; }
        p {
          padding-right: 30px;
          padding-left: 30px;
          font-size: 18px;
          line-height: 2.0;
          span {
            display: inline-block;
            position: relative;
            em {
              text-align: center;
              font-style: normal;
              font-size: 10px;
              position: absolute;
              display: block;
              width: 100%;
              left: 50%;
              top: -10px;
              transform: translate(-50%,0px); } } } } }

    section.novels,
    section.comics {
      padding-top: 90px;
      padding-bottom: 75px;
      h2 {
        text-align: center;
        font-size: 24px;
        letter-spacing: 0.18em;
        margin-bottom: 60px;
        font-weight: 500; }
      ul {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        font-size: 0px;
        letter-spacing: -10000em;
        li {
          display: inline-block;
          text-align: center;
          width: calc(50% - 8px);
          margin-bottom: 15px;
          &:nth-child(even) {
            margin-left: 15px; }
          img {
            border: 1px solid $red;
            width: 100%; } } } }

    section.white {
      background: white;
      h2 {
        color: black; } }
    section.black {
      background: black;
      h2 {
        color: white; } }

    section.staff,
    section.cast {
      background: black;
      padding-left: 15px;
      padding-right: 15px;
      padding-top: 90px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        position: relative; }
      ul {
        li {
          font-size: 18px;
          width: calc(50% - 8px);
          float: left;
          text-align: center;
          margin-bottom: 100px;
          position: relative;
          &:last-child {
            margin-bottom: 0px; }
          &:before {
            position: absolute;
            content: "";
            display: block;
            top: -50px;
            left: 50%;
            margin-left: -24px;
            width: 45px;
            height: 3px;
            background: $red; }
          span {
            display: block;
            font-size: 15px;
            color: $gold;
            font-weight: 500;
            margin-bottom: 12px; }
          em {
            margin-top: 12px;
            font-style: normal;
            font-size: 12px;
            display: block; } } } }

    section.staff {
      ul {
        li {
          &:first-child {
            &:before {
              display: none; } }
          &.l {
            width: 100%;
            margin-right: 0px; } } } }

    section.cast {
      ul {
        li {
          &:nth-child(1),
          &:nth-child(2) {
            &:before {
              display: none; } }
          &:last-child {
            margin-bottom: 0px; } } } }


    section.characters {
      .chr {
        &:nth-child(even) {}
        margin-bottom: 90px;
        padding-top: 420px;
        &#ch01 {
          padding-top: 420px;
          figure {
            height: 420px;
            top: -420px; } }
        &#ch02 {
          padding-top: 420px;
          figure {
            height: 420px;
            top: -420px; } }
        &#ch03 {
          padding-top: 260;
          figure {
            height: 260;
            top: -260px; } }
        &#ch04 {
          padding-top: 380px;
          figure {
            height: 380px;
            top: -380px; } }
        &#ch05 {
          padding-top: 390px;
          figure {
            height: 390px;
            top: -390px; } }
        &#ch06 {
          padding-top: 410px;
          figure {
            height: 410px;
            top: -410px; } }
        &#ch07 {
          padding-top: 420px;
          figure {
            height: 420px;
            top: -420px; } }
        &#ch08 {
          padding-top: 440px;
          figure {
            height: 440px;
            top: -440px; } }
        >.inner {
          background: black;
          color: white;
          figure {
            height: 420px;
            position: absolute;
            overflow: hidden;
            top: -420px;
            left: 50%;
            transform: translate(-50%, 0px); }
          .txt {
            margin: 0 auto;
            position: relative;
            > .inner {
              padding-top: 66px;
              padding-bottom: 66px;
              padding-left: 27px;
              padding-right: 27px; }
            .name {
              font-size: 24px;
              margin-bottom: 32px; }
            .cv {
              font-size: 15px;
              color: $gold; }
            .desc {
              font-size: 15; } } } } }

    section.categories {
      margin-bottom: 60px;
      nav {
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        ul {
          font-size: 0px;
          letter-spacing: -10000em;
          li {
            font-size: 18px;
            letter-spacing: 0em;
            display: inline-block;
            margin-bottom: 30px;
            &.current {
              a {
                color: $black; } }
            a {
              color: $gold;
              text-decoration: none;
              padding-left: 15px;
              padding-right: 15px;
              display: block; } } } } }

    section.more {
      text-align: center;
      a {
        text-decoration: none;
        letter-spacing: 0.18em;
        display: block;
        background: black;
        color: white;
        font-size: 24px;
        line-height: 96px; } }

    section.news-items {
      padding-left: 15px;
      padding-right: 15px;
      > .inner {
        .item {
          margin-bottom: 60px;
          &:last-child {
            margin-bottom: 90px; }
          > .inner {
            position: relative;
            .info {
              line-height: 1.0;
              text-align: center;
              margin-bottom: 30px;
              .date {
                letter-spacing: 0.18em;
                font-size: 24px;
                color: white;
                line-height: 48px; }
              .category {
                font-size: 18px;
                color: black;
                line-height: 21px; } }
            .entry {
              background: black;
              padding: 45px 15px;
              box-sizing: border-box;
              font-size: 15px;
              img {
                width: calc(100% + 30px + 30px);
                margin-left: -30px; }
              .title {
                font-size: 18px;
                margin-bottom: 45px; }
              .body {
                a {
                  color: $red; } } } } } } }

    section.subtitles {
      margin-bottom: 60px;
      padding-left: 15px;
      padding-right: 15px;
      nav {
        margin: 0 auto;
        text-align: center;
        ul {
          font-size: 0px;
          letter-spacing: -10000em;
          li {
            font-size: 18px;
            width: 50%;
            letter-spacing: 0em;
            display: inline-block;
            margin-bottom: 30px;
            &.current {
              a {
                color: black; } }
            a {
              color: $gold;
              text-decoration: none;
              display: block;
              line-height: 1.0;
              span {}
              .ep {
                font-size: 18px; }
              .num {
                font-size: 18px; }
              .ttl {
                display: none; } } } } } }

    section.story {
      background: black;
      padding-top: 60px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        span.ruby {
          display: inline-block;
          position: relative;
          em {
            text-align: center;
            font-style: normal;
            font-size: 10px;
            position: absolute;
            display: block;
            width: 200%;
            left: 50%;
            top: -9px;
            transform: translate(-50%,0px); } }
        .story_title {
          padding-bottom: 120px;
          line-height: 1.0;
          text-align: center;
          span {
 }            // display: block
          .num {
            font-size: 30px;
            margin-bottom: 19px; }
          .ttl {
            letter-spacing: 0.18em;
            font-size: 15px;
            margin-bottom: 10px;
            line-height: 2.0; }
          .en {
            color: $gold;
            font-size: 12px; } }
        .still-l {
          img {
            width: 100%; } }
        .movie {
          width: 100vw;
          iframe {
            width: 100vw;
            height: calc(100vw / 16 * 9); } }
        .still,.stills {
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 60px;
          margin-bottom: 60px;
          img {
            margin-bottom: 60px;
            width: 100%;
            &:last-child {
              margin-bottom: 0px; } } }
        .story {
          padding-left: 15px;
          padding-right: 15px;
          margin-top: 60px;
          margin-bottom: 60px;
          font-size: 15px;
          line-height: 2.0; } } }

    section.tile-items {
      padding-left: 15px;
      padding-right: 15px;
      > .inner {
        .items {
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            display: inline-block;
            vertical-align: top;
            font-size: 24px;
            letter-spacing: 0em;
            box-sizing: border-box;
            margin-bottom: 60px;
            figure {
              a {
                display: block;
                img {
                  width: 100%; } } }
            .title {
              font-size: 18;
              line-height: 2.0;
              margin-top: 35px;
              margin-bottom: 20px; }
            .sub {
              font-size: 15px;
              line-height: 2.0;
              .date {
                display: inline-block;
                margin-right: 24px; }
              a {
                color: black; } } } } } }

    section.special {
      padding-top: 90px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 32px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .desc {
          font-size: 12px;
          margin-bottom: 60px;
          text-align: center;
          letter-spacing: 0.18em; } } }

    section.times {
      background: black;
      color: white;
      .items {
        text-align: center;
        .item {
          margin-bottom: 60px;
          &:last-child {
            margin-bottom: 0px; }
          figure {
            width: 165px;
            margin: 0 auto;
            margin-bottom: 60px;
            img {
              width: 100%; } }
          .vol {
            display: none; }
          a {
            display: inline-block;
            width: 255px;
            font-size: 18px;
            background: $red;
            color: white;
            line-height: 48px; } } } }

    section.voice {
      background: white;
      color: black;
      padding-bottom: 66px;
      .items-l {
        text-align: center;
        margin-bottom: 66px;
        > .item {
          display: inline-block;
          width: 100%;
          font-size: 0px;
          letter-spacing: -10000em;
          img {
            vertical-align: 0px;
            margin-bottom: 30px; }
          .btn_sound {
            display: inline-block;
            text-align: center;
            width: 80px;
            height: 80px;
            background: $red;
            border-radius: 80px;
            transition: opacity 1.0s $easeOutQuint;
            position: relative;
            img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%,-50%); } }
          .txt {
            margin-top: 30px;
            font-size: 0px;
            letter-spacing: 0em;
            display: block;
            text-align: center;
            color: $red;
            .date {
              font-size: 24px;
              letter-spacing: 0.18em; }
            .name {
              font-size: 15px; } } } }
      .items {
        text-align: center;
        font-size: 0px;
        letter-spacing: -10000em;
        > .item {
          display: inline-block;
          width: 75px;
          line-height: 1.0;
          margin-left: 7px;
          margin-right: 7px;
          margin-bottom: 36px;
          img {
            width: 75px; }
          .txt {
            letter-spacing: 0em;
            font-size: 15px;
            margin-top: 15px; } } } }

    section.music {
      padding-top: 90px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 32px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .title {
          text-align: center;
          font-weight: 500;
          margin-bottom: 60px;
          letter-spacing: 0.18em;
          &.title-s {
            h3 {
              font-size: 24px; } }
          .artist {
            font-size: 18px;
            margin-bottom: 10px; }
          h3 {
            letter-spacing: 0.06em;
            font-size: 30px;
            margin-bottom: 32px;
            font-weight: 500; }
          .credit {
            font-size: 12px; } }
        .txts {
          position: relative;
          padding-left: 15px;
          padding-right: 15px;
          img {
            width: 100%;
            margin-bottom: 60px; }
          .txt-block {
            margin-bottom: 60px;
            font-size: 15px;
            &:last-child {
              margin-bottom: 0px; }
            .head {
              letter-spacing: 0.18em; }
            ul,ol {
              margin-bottom: 1em; }
            ol {
              li {
                list-style-type: decimal-leading-zero;
                margin-left: 1.6em; } } } } } }

    section.music.white {
      background: white;
      color: black;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $red; }
            a {
              color: $red; } } } } }

    section.music.black {
      background: black;
      color: white;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $gold; }
            a {
              color: $gold; } } } } }

    section.onair {
      padding-top: 90px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 32px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        ul {
          li {
            font-size: 15px;
            // width: calc(50% - 8px)
            // float: left
            text-align: center;
            margin-bottom: 60px;
            position: relative;
            &:last-child {
              margin-bottom: 0px; }
            span {
              display: block;
              font-size: 18px;
              color: $gold;
              font-weight: 500;
              margin-bottom: 12px; }
            em {
              font-style: normal;
              display: block; } } } } }

    section.onair.white {
      background: white;
      color: black;
      > .inner {
        ul {
          li {
            span {
              color: $red; } } } } }

    section.onair.black {
      background: black;
      color: white;
      > .inner {
        ul {
          li {
            span {
              color: $gold; } } } } }

    section.disc {
      padding-top: 90px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 60px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        img {
          width: 100%;
          box-sizing: border-box;
          margin-bottom: 60px;
          border: 1px solid $red; }
        .txts {
          position: relative;
          padding-left: 15px;
          padding-right: 15px;
          a.pv {
            display: block;
            text-align: center;
            background: $red;
            width: 255px;
            line-height: 1.0;
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 auto; }
          a.aniplexplus {
            display: block;
            text-align: center;
            background: white;
            color: black;
            width: 255px;
            line-height: 1.0;
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 auto;
            margin-top: 15px; }
          .txt-block {
            margin-bottom: 60px;
            font-size: 15px;
            &:last-child {
              margin-bottom: 0px; }
            .head {
              margin-top: 30px;
              letter-spacing: 0.18em; }
            ol {
              li {
                list-style-type: decimal-leading-zero;
                margin-left: 1.6em; } }
            ul {
              li {
                list-style-type: disc;
                margin-left: 1.6em; } } }
          .block-l {
            .head {
              margin-top: 0px; }
            p.body-l {
              margin-top: 36px;
              font-size: 18px;
              span.ruby {
                display: inline-block;
                position: relative;
                em {
                  text-align: center;
                  font-style: normal;
                  font-size: 10px;
                  position: absolute;
                  display: block;
                  width: 200%;
                  left: 50%;
                  top: -9px;
                  transform: translate(-50%,0px); } } } } } } }

    section.disc.white {
      background: white;
      color: black;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $red; }
            a {
              color: $red; } } } } }

    section.disc.black {
      background: black;
      color: white;
      > .inner {
        .txts {
          .txt-block {
            .head {
              color: $gold; }
            a {
              color: $gold; } } } } }

    section.bonus {
      padding-top: 90px;
      padding-bottom: 0px;
      > .inner {
        margin: 0 auto;
        .txts {
          position: relative;
          padding-left: 15px;
          padding-right: 15px;
          .txt-block {
            margin-bottom: 60px;
            font-size: 15px;
            .head {
              margin-bottom: 32px;
              letter-spacing: 0.18em;
              color: $gold; }
            .body {
              span {
                color: $gold; } } } } } }

    section.radio {
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 64px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500;
          color: $gold; }
        p {
          margin: 0 auto;
          padding-left: 30px;
          padding-right: 30px;
          font-size: 15px; } } }

    section.radio-program {
      padding-top: 90px;
      padding-bottom: 30px;
      background: black;
      color: white;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 32px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .ps {
          .p {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 15px;
            margin: 0 auto;
            margin-bottom: 60px;
            .num {
              font-size: 30px;
              color: $gold;
              text-align: center;
              line-height: 1.0;
              margin-bottom: 40px; }
            .title {
              font-size: 18px;
              color: $gold;
              text-align: center;
              // line-height: 1.0
              margin-bottom: 40px; }
            a {
              color: $gold; } } } } }

    section.radio-links {
      padding-top: 90px;
      padding-bottom: 90px;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 60px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .links {
          text-align: center;
          a {
            &:first-child {
              margin-top: 0px; }
            display: block;
            text-align: center;
            background: white;
            color: black;
            width: 255px;
            line-height: 1.0;
            font-size: 18px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 auto;
            margin-top: 15px; } } } }

    section.radio-archives {
      padding-top: 90px;
      padding-bottom: 90px;
      background: black;
      color: white;
      > .inner {
        margin: 0 auto;
        h2 {
          font-size: 24px;
          margin-bottom: 60px;
          text-align: center;
          letter-spacing: 0.18em;
          font-weight: 500; }
        .links {
          text-align: left;
          font-size: 0px;
          letter-spacing: -10000em;
          padding-left: 15px;
          padding-right: 15px;
          a {
            &:first-child {
              margin-top: 0px; }
            letter-spacing: 0em;
            display: inline-block;
            text-align: center;
            background: white;
            color: black;
            width: calc(50% - 8px);
            line-height: 1.0;
            font-size: 30px;
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 0 auto;
            margin-top: 15px;
            margin-left: 15px;
            &:nth-child(odd) {
              margin-left: 0px; }
            span {
              line-height: 18px;
              display: block;
              font-size: 18px;
              margin-top: 15px; } } } } }

    section.countdown {
      background: black;
      color: white;
      padding-bottom: 30px;
      .items {
        text-align: center;
        font-size: 0px;
        letter-spacing: -10000em;
        padding-left: 15px;
        padding-right: 15px;
        .item {
          width: calc(50% - 8px);
          display: inline-block;
          font-size: 18px;
          letter-spacing: 0em;
          margin-bottom: 60px;
          vertical-align: top;
          margin-left: 15px;
          &:nth-child(odd) {
            margin-left: 0px; }
          &:last-child {
            margin-bottom: 0px; }
          figure {
            // width: 165px
            margin: 0 auto;
            margin-bottom: 15px;
            img {
              width: 100%; } }
          .desc {
            margin-bottom: 0px; }
          .vol {
            font-size: 30px;
            letter-spacing: 0.18em;
            margin-bottom: 20px; } } } }

    section.location {
      .inner {
        display: none;
        &:first-child {
          display: block; } }
      >.inner {
        padding-left: 15px;
        padding-right: 15px; }
      nav {
        text-align: center;
        margin-bottom: 45px;
        li {
          display: inline-block;
          margin: 0px 10px;
          a {
            letter-spacing: 0.18em;
            font-size: 18px;
            color: $gold;
            display: block;
            &.current {
              color: black; } } } }
      .content {
        background: black;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 30px;
        padding-top: 30px;
        .items {
          font-size: 0px;
          letter-spacing: -10000em;
          .item {
            vertical-align: top;
            letter-spacing: 0em;
            margin-bottom: 30px;
            &:last-child {
              margin-bottom: 0px; } }
          .photo {
            span {
              line-height: 1.0;
              font-size: 24px;
              color: $gold;
              margin-bottom: 15px;
              display: inline-block; }
            figure {
              width: 100%;
              img {
                width: 100%; } } }
          .desc {
            text-align: left;
            font-size: 15px;
            p {
              margin-bottom: 1em;
              span {
                color: $gold; } } } } } } } }
